import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { LifestylesListItemEditLinkLifestyle } from './LifestylesListItemEditLink.type';

import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';

import { LifestylePath } from '../../../LifestylePath';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { words } from '../../../../../locales/keys';

interface LifestylesListItemEditLinkProps {
  lifestyle: LifestylesListItemEditLinkLifestyle;
  onMouseEnter?: () => void;
}

function LifestylesListItemEditLink({
  lifestyle,
  onMouseEnter
}: LifestylesListItemEditLinkProps) {
  return (
    <NextPureTooltipIconLinkHelper
      href={LifestylePath.edit(lifestyle.nanoId)}
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      dataGa="lifestyles-lifestyle-edit-link"
      icon={IconsEnum.PENCIL}
      iconClassName="h-5 w-5 stroke-1.75"
      tooltipI18nText={words.edit}
      tooltipPlacement={TooltipPlacement.LEFT}
      onMouseEnter={onMouseEnter}
      tooltipSingleton
    />
  );
}

export default LifestylesListItemEditLink;
