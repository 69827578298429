import { useCallback } from 'react';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction,
  UpdateShowLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  TOGGLE_LIFESTYLE_FAVORITE_QUERY,
  ToggleLifestyleFavoriteQueryResponse
} from '../../../../../queries/toggleLifestyleFavorite.query';

import { useToggleLifestyleFavorite } from '../../../../../hooks/useToggleLifestyleFavorite';
import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { LifestyleFavoriteButtonLifestyle } from '../../LifestyleFavoriteButton.types';

interface useLifestyleFavoriteButtonProps {
  lifestyle: LifestyleFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateIndexLifestyleCache: UpdateLifestyleCacheAction<LifestyleFavoriteButtonLifestyle>;
  updateShowLifestyleCache?: UpdateShowLifestyleCacheAction<LifestyleFavoriteButtonLifestyle>;
}

function useLifestyleFavoriteButton({
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateIndexLifestyleCache,
  updateShowLifestyleCache
}: useLifestyleFavoriteButtonProps) {
  const {
    toggleLifestyleFavorite,
    toggleLifestyleFavoriteErrorMessage,
    toggleLifestyleFavoriteLoading
  } = useToggleLifestyleFavorite<ToggleLifestyleFavoriteQueryResponse>({
    query: TOGGLE_LIFESTYLE_FAVORITE_QUERY,
    // Disable immediate invalidateQueries (use optimistic update instead)
    // cacheKeys: compact([
    //   LifestyleCache.indexCacheKey(),
    //   LifestylesSetCache.showCacheKey(),
    //   ...(lifestylesCacheKeys || []),
    //   ...(lifestylesSetCacheKeys || [])
    // ]),
    updateIndexLifestyleCache,
    updateShowLifestyleCache
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleLifestyleFavorite({ uuid: lifestyle.uuid }),
    [lifestyle, toggleLifestyleFavorite]
  );

  useShowToastOnErrorChange({ error: toggleLifestyleFavoriteErrorMessage });

  return {
    handleToggleFavorite,
    toggleLifestyleFavoriteLoading
  };
}

export default useLifestyleFavoriteButton;
