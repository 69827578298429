import { useCallback } from 'react';

import {
  LifestyleFavorite,
  LifestyleUUID,
  ToggleLifestyleFavoriteCacheKeys,
  ToggleLifestyleFavoriteGqlError,
  ToggleLifestyleFavoriteGqlQuery,
  ToggleLifestyleFavoriteGqlStatus,
  UpdateLifestyleCacheAction,
  UpdateShowLifestyleCacheAction
} from '../../lifestylesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { LifestyleFavoriteButtonLifestyle } from '../../components/buttons/LifestyleFavoriteButton';

interface ToggleLifestyleFavoriteInput {
  clientMutationId?: string;
  uuid: LifestyleUUID;
}

interface ToggleLifestyleFavoriteError {
  fullMessages: ToggleLifestyleFavoriteGqlError;
}

interface ToggleLifestyleFavoriteResponse<ToggleLifestyleFavoriteItemType> {
  toggleLifestyleFavorite: {
    status: ToggleLifestyleFavoriteGqlStatus;
    recordUuid: LifestyleUUID;
    record: ToggleLifestyleFavoriteItemType;
    errors: ToggleLifestyleFavoriteError;
  };
}

interface ToggleLifestyleFavoriteOptions<ToggleLifestyleFavoriteItemType> {
  query: ToggleLifestyleFavoriteGqlQuery;
  cacheKeys?: ToggleLifestyleFavoriteCacheKeys;
  updateIndexLifestyleCache?: UpdateLifestyleCacheAction<ToggleLifestyleFavoriteItemType>;
  updateShowLifestyleCache?: UpdateShowLifestyleCacheAction<LifestyleFavoriteButtonLifestyle>;
}

const action = 'toggleLifestyleFavorite';

interface ToggleLifestyleFavoriteItem {
  favorite: LifestyleFavorite;
}

function useToggleLifestyleFavorite<
  ToggleLifestyleFavoriteItemType extends ToggleLifestyleFavoriteItem
>({
  query,
  cacheKeys,
  updateIndexLifestyleCache,
  updateShowLifestyleCache
}: ToggleLifestyleFavoriteOptions<ToggleLifestyleFavoriteItemType>) {
  const handleOptimisticUpdate = useCallback<
    (input: ToggleLifestyleFavoriteInput) => null | (() => void)
  >(
    (input) => {
      const updateIndexLifestyleCacheRollback = updateIndexLifestyleCache?.({
        selector: { uuid: input?.uuid },
        updateFunction: (prevLifestyle) => ({
          ...prevLifestyle,
          favorite: !prevLifestyle?.favorite
        })
      });

      const updateShowLifestyleCacheRollback = updateShowLifestyleCache?.(
        (prevLifestyle) => ({
          ...prevLifestyle,
          favorite: !prevLifestyle?.favorite
        })
      );

      return () => {
        updateIndexLifestyleCacheRollback?.();
        updateShowLifestyleCacheRollback?.();
      };
    },
    [updateIndexLifestyleCache, updateShowLifestyleCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleLifestyleFavoriteInput,
    ToggleLifestyleFavoriteResponse<ToggleLifestyleFavoriteItemType>,
    ToggleLifestyleFavoriteError,
    ToggleLifestyleFavoriteItemType
  >({
    action,
    cacheKeys,
    query,
    onOptimisticUpdate:
      updateIndexLifestyleCache || updateShowLifestyleCache
        ? handleOptimisticUpdate
        : undefined
  });

  return {
    toggleLifestyleFavoriteData: updateQueryData,
    toggleLifestyleFavoriteError: updateQueryError,
    toggleLifestyleFavoriteLoading: updateQueryLoading,
    toggleLifestyleFavoriteErrorMessage: updateQueryErrorMessage,
    toggleLifestyleFavorite: updateQuery,
    toggleLifestyleFavoriteReset: updateQueryReset
  };
}

export default useToggleLifestyleFavorite;
