import get from 'lodash/get';
import includes from 'lodash/includes';

import { graphQLCacheClient } from '../../graphQLClients';

import { UpdateItemGqlQuery } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { errorMessageStatusOrErrorsIsIncorrect } from '../../reactQueryConstants';

interface UpdateCacheItemProps<UpdateItemInput> {
  action: string;
  query: UpdateItemGqlQuery;
  queryInput: UpdateItemInput;
}

const successQueryStatuses = ['updated', 'success'];

export function updateCacheItem<UpdateCacheItemInput, UpdateCacheItemResponse>({
  action,
  query,
  queryInput
}: UpdateCacheItemProps<UpdateCacheItemInput>) {
  const { rawName, name } = getQueryName(query, 'mutation');

  productionConsole(
    'log',
    'updateCacheItem',
    { action, queryInput },
    rawName,
    JSON.stringify(queryInput)
  );

  const requestId = generateUuid();

  graphQLCacheClient.addQueryParams(name, requestId);

  return new Promise<UpdateCacheItemResponse>((resolve, reject) => {
    graphQLCacheClient
      .request<UpdateCacheItemResponse, UpdateCacheItemInput>(
        query,
        queryInput,
        {
          requestId
        }
      )
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        const errors = get(response, `${action}.errors`);

        reject(errors ? errors : errorMessageStatusOrErrorsIsIncorrect);
      })
      .catch((reason) => reject(reason));
  });
}
