import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadLifestyle } from '../../../../../hooks/useDownloadLifestyle';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../../../locales/keys';

import { LifestylesListItemLightboxDownloadButtonLifestyle } from './LifestylesListItemLightboxDownloadButton.types';

interface LifestylesListItemLightboxFavoriteButtonProps {
  lifestyle: LifestylesListItemLightboxDownloadButtonLifestyle;
}

function LifestylesListItemLightboxFavoriteButton({
  lifestyle
}: LifestylesListItemLightboxFavoriteButtonProps) {
  const {
    downloadLifestyleLoading,
    downloadLifestyleErrorMessage,
    downloadLifestyle
  } = useDownloadLifestyle();

  useShowToastOnErrorChange({ error: downloadLifestyleErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();
  const lifestyleUuid = lifestyle.uuid;

  const handleClick = useCallback(() => {
    downloadLifestyle({
      uuid: lifestyleUuid,
      deviceNanoId: downloadNanoId
    });
  }, [lifestyleUuid, downloadNanoId, downloadLifestyle]);

  return (
    <PureTooltipIconButtonHelper
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      dataGa="lifestyle-lightbox-download-button"
      icon={IconsEnum.DOWNLOAD_OUTLINE}
      iconClassName="h-6 w-6"
      tooltipSingleton
      tooltipI18nText={words.download}
      disabled={downloadLifestyleLoading}
      onClick={handleClick}
    />
  );
}

export default LifestylesListItemLightboxFavoriteButton;
