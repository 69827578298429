import {
  AssociationIDsFilter,
  BooleanFilter,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  ItemGqlError,
  TextFilterType,
  StringFilter,
  UuidFilterType,
  DateFilterType,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UpdateItemCacheKeys
} from '../../../types';
import {
  FetchFinCategoriesFilters,
  FetchCategoriesLimit,
  FetchCategoriesPage,
  FetchCategoriesSort,
  FetchCategoriesFilters
} from '../../categories/categoriesTypes';

export interface FetchLifestylesFilters {
  id?: IdFilterType;
  uuid?: UuidFilterType;
  name?: TextFilterType;
  nameTags?: StringFilter;
  authorId?: IdFilterType;
  lifestyleCategoryId?: IdFilterType;
  lifestyleParentCategoryIds?: AssociationIDsFilter;
  styleIds?: AssociationIDsFilter;
  gammaId?: IdFilterType;
  toneId?: IdFilterType;
  materialIds?: AssociationIDsFilter;
  mainColorIds?: AssociationIDsFilter;
  favorite?: BooleanFilter;
  currentTeamFavorite?: BooleanFilter;
  blocked?: BooleanFilter;
  nda?: BooleanFilter;
  clientIds?: AssociationIDsFilter;
  productIds?: AssociationIDsFilter;
  premiumContentAt?: DateFilterType;
}

export type FetchLifestylesFiltersToPersist = (keyof FetchLifestylesFilters)[];

export enum FetchLifestylesSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchLifestylesFilterFields {
  NAME = 'name',
  NAME_TAGS = 'nameTags',
  CATEGORY_ID = 'categoryId',
  LIFESTYLE_CATEGORY_ID = 'lifestyleCategoryId',
  LIFESTYLE_PARENT_CATEGORY_IDS = 'lifestyleParentCategoryIds',
  STYLE_IDS = 'styleIds',
  MATERIAL_IDS = 'materialIds',
  MAIN_COLOR_IDS = 'mainColorIds',
  FAVORITE = 'favorite',
  NDA = 'nda',
  BLOCKED = 'blocked',
  CREATED_AT = 'createdAt',
  TONE_ID = 'toneId',
  GAMMA_ID = 'gammaId',
  AUTHOR_ID = 'authorId',
  CLIENT_IDS = 'clientIds',
  LIFESTYLE_TYPE_ID = 'lifestyleTypeId'
}

export enum FetchLifestylesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchLifestylesGqlQuery = FetchItemsGqlQuery;

export type FetchLifestylesCacheKey = FetchItemsCacheKey;
export type FetchLifestylesCacheKeys = FetchLifestylesCacheKey[];
export type FetchLifestyleCategoriesCacheKey = FetchItemsCacheKey;

export type FetchLifestylesFetched = FetchItemsFetched;
export type FetchLifestylesErrorMessage = FetchItemsErrorMessage;
export type FetchLifestylesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchLifestylesPage = FetchItemsPage;
export type FetchLifestylesLimit = FetchItemsLimit;
export type FetchLifestylesSort = FetchLifestylesSortTypes[];
export type FetchLifestylesTotalCount = FetchItemsTotalCount;
export type FetchLifestylesFilterLifestyles =
  FetchItemsFilterItems<FetchLifestylesFilters>;
export type FetchLifestylesSortLifestyles = FetchItemsSortItems;
export type FetchLifestylesPaginateLifestyles = FetchItemsPaginateItems;
export type FetchLifestylesPrefetchLifestyles = FetchItemsPrefetchItems;
export type FetchLifestylesClearLifestylesFilters = FetchItemsClearItemsFilters;
export type FetchLifestylesShowLifestyle = FetchItemsShowItem;
export type FetchLifestylesEditLifestyle = FetchItemsEditItem;

export type FetchLifestyleCategoriesFilters = FetchCategoriesFilters;
export type FetchFinLifestyleCategoriesFilters = FetchFinCategoriesFilters;
export type FetchLifestyleCategoriesLimit = FetchCategoriesLimit;
export type FetchLifestyleCategoriesSort = FetchCategoriesSort;
export type FetchLifestyleCategoriesPage = FetchCategoriesPage;

export type DeleteLifestyleGqlQuery = DeleteItemGqlQuery;
export type DeleteLifestyleGqlStatus = DeleteItemGqlStatus;
export type ToggleLifestyleBlockedGqlQuery = UpdateItemGqlQuery;
export type ToggleLifestyleBlockedCacheKeys = UpdateItemCacheKeys;
export type ToggleLifestyleBlockedGqlStatus = UpdateItemGqlStatus;
export type LifestyleGqlError = ItemGqlError;
